import React from 'react';
import ReactGaugeChart from 'react-gauge-chart';
import { colors } from '@propertylens/app-commons/dist/tailwindBaseConfig';
import classNames from 'classnames';
import './styles.scss';

const chartColors = [
  colors['pl-rating-red'],
  colors['pl-rating-orange'],
  colors['pl-rating-yellow'],
  colors['pl-rating-light-green'],
  colors['pl-rating-green'],
];

type GaugeChartProps = {
  title: string;
  value: number;
  className?: string;
  description?: string;
  descendingMaxToMin?: boolean;
  formatTextValue?: (value: string) => string;
  gaugeWrapperClassName?: string;
  hideText?: boolean;
  nrOfLevels?: number;
  subLabel?: string;
};

const GaugeChart: React.FC<GaugeChartProps> = ({
  className,
  description,
  descendingMaxToMin = false,
  formatTextValue = () => '',
  gaugeWrapperClassName,
  hideText = false,
  nrOfLevels = 5,
  subLabel,
  title = 'Precise Roof Score',
  value = 0,
}) => {
  const containerCX = classNames('GaugeChart', className);
  const labelCx = classNames('font-bold', {
    'text-pl-rating-red': 0 <= value && value < 1,
    'text-pl-rating-orange': 1 <= value && value < 2,
    'text-pl-rating-yellow': 2 <= value && value < 3,
    'text-pl-rating-light-green': 3 <= value && value < 4,
    'text-pl-rating-green': 4 <= value && value <= 5,
  });

  const titleCx = classNames(
    labelCx,
    'mt-[18px] font-medium text-center leading-tight'
  );

  function transformValue(inputValue: number): number {
    if (inputValue < 0 || inputValue > 5) {
      throw new Error('Input value must be between 0 and 5');
    }
    return inputValue / 5;
  }

  function getTextColor(): string {
    if (value < 1) {
      return colors['pl-rating-red'];
    } else if (value < 2) {
      return colors['pl-rating-orange'];
    } else if (value < 3) {
      return colors['pl-rating-yellow'];
    } else if (value < 4) {
      return colors['pl-rating-light-green'];
    } else {
      return colors['pl-rating-green'];
    }
  }

  return (
    <div className={containerCX}>
      <div
        className={classNames('m-auto', {
          [`${gaugeWrapperClassName}`]: gaugeWrapperClassName,
          'w-[220px]': !gaugeWrapperClassName,
        })}
      >
        <ReactGaugeChart
          className="chart-component"
          colors={descendingMaxToMin ? chartColors.reverse() : chartColors}
          formatTextValue={formatTextValue}
          hideText={hideText}
          marginInPercent={0}
          needleColor={colors['pl-gray-light']}
          nrOfLevels={nrOfLevels}
          percent={transformValue(value)}
          textColor={getTextColor()}
          // for some reason this prop isn't being recognized by the typescript compiler
          // @ts-ignore
          // Needed to use hexadecimal rather than the tailwind color name
        />
      </div>
      <div className={titleCx}>{title}</div>
      <div className="text-center text-sm font-bold">{subLabel}</div>
      <div className="text-center text-xs">{description}</div>
    </div>
  );
};

export default GaugeChart;
