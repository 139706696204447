'use client';

import { useEffect, useState, FC } from 'react';
import * as Sentry from '@sentry/react';

import {
  useModalContext,
  ModalErrorView,
  ModalView,
  ModalContextType,
} from '../../../hooks/useModal';

import Button from '../../Button';
import InfoBox from '../../InfoBox';
import { ModalBody } from '@nextui-org/react';
import ModalHeader from './ModalHeader';
import SectionHeadingGroup from '../../SectionHeadingGroup';
import {
  SelectedPlace,
  SENTRY_ERRORS,
  emitTrackingEvent,
} from '@propertylens/app-commons';
import { removeNullValues } from '../../../utils/removeNullValues';
type Props = {
  handleOnBack: () => void;
  handleRedirectToCheckout: () => void;
  getReport: (digitalPurchaseId: number, selectedPlace: SelectedPlace) => void;
  digitalPurchaseId?: number;
  handleChordReport: (
    selectedPlace: SelectedPlace,
    previewImageUrl: string | null
  ) => void;
  isLoggedIn?: boolean;
};

const PreviewContent: FC<Props> = ({
  handleRedirectToCheckout,
  handleOnBack,
  getReport,
  digitalPurchaseId,
  handleChordReport,
  isLoggedIn = false,
}: Props) => {
  const {
    selectedPlace,
    countOfUnredeemed,
    setModalView,
    setModalErrorView,
    hasCredit,
    setBlobImage,
  } = useModalContext() as ModalContextType;
  const { state, locality, zipCode, address1, address2, latitude, longitude } =
    selectedPlace;
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);

  // This env var should only be set on the checkout app, so we use it as a flag to change functionality
  const isCheckoutApp = process.env.NEXT_PUBLIC_IN_APP_DOMAIN ? true : false;
  /**
   *
   * Fetch image from google maps API
   * Converts the image to blob and sets the blob image
   * Sets the preview image url
   */
  const createImgData = async () => {
    try {
      let googleImageResponse;
      googleImageResponse = await fetch(
        `https://maps.googleapis.com/maps/api/streetview?location=${latitude},${longitude}&size=520x250&return_error_code=true&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`
      );
      if (googleImageResponse.status === 404) {
        googleImageResponse = await fetch(
          `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=20&size=520x250&maptype=satellite&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`
        );
      }
      const blob = await googleImageResponse.blob();
      const imageUrl = URL.createObjectURL(blob);
      setBlobImage(blob);
      setPreviewImageUrl(imageUrl);
    } catch (error) {
      Sentry.captureMessage(SENTRY_ERRORS['FETCH_IMAGE_ERROR']);
      Sentry.captureException({
        data: SENTRY_ERRORS['FETCH_IMAGE_ERROR'],
      });
    }
  };

  useEffect(() => {
    createImgData();
  }, []);

  const handleRedeemReport = () => {
    emitTrackingEvent('Address Verified', {
      address: {
        ...selectedPlace,
      },
      hasCredit: hasCredit,
    });
    if (isCheckoutApp) {
      // fb pixel conversion
      try {
        // @ts-ignore
        fbq('track', 'InitiateCheckout');
      } catch (error) {
        Sentry.captureException(error);
      }
      handleChordReport(selectedPlace, previewImageUrl);
      return;
    }
    if (digitalPurchaseId) {
      getReport(digitalPurchaseId, selectedPlace);
    } else {
      setModalView(ModalView.Error);
      setModalErrorView(ModalErrorView.ErrorOnPreview);
    }
  };

  useEffect(() => {
    if (selectedPlace.latitude && selectedPlace.longitude) {
      const mapContainer = document.getElementById('map-container');
      if (mapContainer && window?.google?.maps?.Map) {
        const map = new window.google.maps.Map(mapContainer, {
          center: {
            lat: selectedPlace.latitude,
            lng: selectedPlace.longitude,
          },
          zoom: 15,
          disableDefaultUI: true,
        });

        new window.google.maps.Marker({
          position: {
            lat: selectedPlace.latitude,
            lng: selectedPlace.longitude,
          },
          map: map,
        });
      }
    }
  }, []);

  const handleRedirect = () => {
    emitTrackingEvent('Address Verified', {
      address: {
        ...selectedPlace,
      },
      hasCredit: false,
    });
    const filteredPlace = removeNullValues(selectedPlace);
    const handoffParams = new URLSearchParams({
      ...filteredPlace,
      intent: 'PREVIEW',
    }).toString();
    window.location.href = `${process.env.NEXT_PUBLIC_IN_APP_DOMAIN}/?${handoffParams}`;
  };

  return (
    <>
      <ModalHeader>
        <SectionHeadingGroup
          level={2}
          heading="Is this the correct property?"
          description="Get your full PropertyLens Report in minutes!"
          classNames={{
            base: 'mt-0 mb-4',
            description: 'mt-4',
            heading: 'font-[raleway]',
          }}
          alwaysVisible={true}
        />
      </ModalHeader>
      <ModalBody className="p-0 lg:gap-5">
        <div>
          <div className="relative mb-4 w-full overflow-hidden">
            <div
              id="map-container"
              className="absolute bottom-0 left-0 z-10 h-[75px] w-[100px]"
            ></div>
            <img
              src={previewImageUrl || ''}
              alt="Your property view"
              width="600"
            />
          </div>
          <p className="font-bold">
            {address1} {address2}
          </p>
          <p>
            {locality}, {state} {zipCode}
          </p>
        </div>
        <div>
          {hasCredit && (
            <InfoBox
              desktopSize="base"
              header={`${countOfUnredeemed} Available PropertyLens Report Credits`}
              className={`mb-7`}
            >
              Simply click the button below to redeem your pre-paid credit and
              get instant access to your report.
            </InfoBox>
          )}
          <div className="flex flex-col justify-end gap-2 lg:flex-row">
            <Button
              radius="sm"
              type="button"
              color="primary"
              size="lg"
              variant="bordered"
              onClick={handleOnBack}
              className="order-last lg:order-first"
              style={{ borderColor: '#050A30' }}
            >
              No, Let’s Try That Again
            </Button>
            <Button
              radius="sm"
              type="button"
              color="primary"
              size="lg"
              onClick={
                hasCredit || isCheckoutApp
                  ? handleRedeemReport
                  : handleRedirectToCheckout
              }
            >
              {hasCredit
                ? 'Yes, Redeem Report Credit'
                : isCheckoutApp
                  ? 'Yes, Get PropertyLens Report'
                  : 'Yes, Go To Secure Checkout'}
            </Button>
          </div>
        </div>
        {isCheckoutApp && !isLoggedIn && (
          <div className="text-center text-sm text-[#858585]">
            <p>
              Have an account?{' '}
              <span
                className="cursor-pointer underline"
                onClick={handleRedirect}
              >
                Log In
              </span>
            </p>
          </div>
        )}
      </ModalBody>
    </>
  );
};

export default PreviewContent;
