import { isPlaceValid } from '@propertylens/app-commons/dist/placeUtils';
import { SelectedPlace } from '@propertylens/app-commons/dist/types';
import validateAddress from './utils/validateAddress'
import icons from './components/Icons/icons';

import AddressCard from './components/AddressCard';
import AddressLookup from './components/AddressLookup';
import Badge from './components/Badge';
import Button from './components/Button';
import Card from './components/Card';
import DetailedList from './components/DetailedList';
import FeaturesList from './components/FeaturesList';
import Icons from './components/Icons';
import InfoBox from './components/InfoBox';
import ImageHeader from './components/ImageHeader';
import ImageHeaderAnimate from './components/ImageHeaderAnimate';
import GaugeChart from './components/GaugeChart';
import Logo, { LogoSimple } from './components/Logo';
import PropertyThumbnail from './components/PropertyThumbnail';
import RatingCard from './components/RatingCard';
import SectionHeading from './components/SectionHeading';
import SectionHeadingGroup from './components/SectionHeadingGroup';
import SectionNavigation from './components/SectionNavigation';
import SpotlightScoreCard from './components/SpotlightScoreCard';
import SpotlightScoreHorizontal from './components/SpotlightScoreHorizontal';
import Timeline from './components/Timeline';
import { GetReportsModal, ValidationState }  from './components/GetReportsModal';
import type { ListValueType, ListItemType, GradeType, LatLng, ServerReport } from './types';
import { RatingsEnum, RatingsTitleCasedEnum, GradeEnum } from './types';
import type { IconNameType } from './components/Icons/Icon';
import type { ListValueEnumType } from './components/DetailedList/DetailedList';
import {ADDRESS_INPUT_ERROR} from './constants'

import { 
  useModalContext, 
  ModalContextProvider, 
  defaultPlace,
  ModalType,
  ModalErrorView, 
  ModalView,
} from './hooks/useModal';
import type  { 
  DigitalPurchase, 
  ModalContextType, 
} from './hooks/useModal';
import { 
  onSort,
  MyReportsContextProvider,
  useMyReportsContext,
  PageState,
  SortBy,
} from './hooks/useMyReports';
import type  { MyReportsContextType } from './hooks/useMyReports';
function hasIcon(icon: string): icon is IconNameType {
  return icon in icons;
}

export {
  AddressCard,
  AddressLookup,
  Badge,
  Button,
  Card,
  DetailedList,
  FeaturesList,
  GaugeChart,
  GetReportsModal,
  Icons,
  InfoBox,
  ImageHeader,
  ImageHeaderAnimate,
  Logo,
  LogoSimple,
  PropertyThumbnail,
  RatingCard,
  SectionHeading,
  SectionHeadingGroup,
  SectionNavigation,
  SpotlightScoreCard,
  SpotlightScoreHorizontal,
  Timeline,

  // Types
  DigitalPurchase, 
  IconNameType,
  LatLng,
  ListItemType,
  ListValueType,
  SelectedPlace,
  ServerReport,
  ModalContextType,
  MyReportsContextType,
  ValidationState,

  // Enums
  GradeEnum,
  GradeType,
  ListValueEnumType,
  ModalErrorView, 
  ModalType,
  ModalView,
  RatingsEnum,
  RatingsTitleCasedEnum,
  PageState,
  SortBy,
  ADDRESS_INPUT_ERROR,

  // Utilities
  defaultPlace,
  hasIcon,
  isPlaceValid,
  onSort,
  validateAddress,

  // hooks
  ModalContextProvider,
  MyReportsContextProvider,
  useModalContext,
  useMyReportsContext,
};
