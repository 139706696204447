import { createContext, useContext, useEffect, useState } from 'react'
import { useIsPreviewing } from '@builder.io/react'
import { useCheckout as useChordCheckout } from '@chordcommerce/react-autonomy'
import { PropsOnlyChildren } from '~/types'
import {
  CHECKOUT_STEP_DEFAULT,
  CHECKOUT_STEP_INTERSTITIAL,
  CHECKOUT_STEP_CHECKOUT
} from '~/utils/constants'
import { getParamObject, getParamObjectSafe } from '~/utils/url'
import { Address } from '~/types';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface CheckoutContextProps {
  address: any
  prepareCheckout: () => any
  finalizeCheckout: () => any
  report: any
  step: string | null
  setAddress: (show: Address | null) => void
  setReport: (show: any | null) => void
  setStep: (show: string | null) => void
}

const CheckoutContext = createContext<CheckoutContextProps>({
  address: null,
  prepareCheckout: () => {},
  finalizeCheckout: () => {},
  report: null,
  step: null,
  setAddress: () => {},
  setReport: () => {},
  setStep: () => {},
})

export const CheckoutProvider: React.FC<PropsOnlyChildren> = ({ children }) => {
  const { prepareCheckout, finalizeCheckout } = useChordCheckout()
  const isPreviewing = useIsPreviewing()
  const [address, setAddress] = useState<Address | null>(null)
  const [report, setReport] = useState<any | null>(null)
  const [step, setStep] = useState<string | null>(CHECKOUT_STEP_INTERSTITIAL)
  
  let addressParam = {};
  if(typeof window !== 'undefined') {
    addressParam = getParamObjectSafe('address');
  }
  /* Grabs address from params and set it to state
  Using useDeepCompareEffect instead of useEffect due to the dependency[addressParam]
  The problem is that if you need to provide an object for one of those dependencies and that object is new every render, then even if none of the properties changed, your effect will get called anyway.
  */
  useDeepCompareEffect(() => {
    const urlAddress: any = getParamObject('address')
    if (urlAddress && !address) {
      setAddress(urlAddress)
    }
  }, [addressParam]);

  // Keeps step in sync with available data.
  useEffect(() => {
    if (!address && !report) {
      setStep(CHECKOUT_STEP_DEFAULT)
    }

    if (address && !report) {
      setStep(CHECKOUT_STEP_INTERSTITIAL)
    }

    if (address && report) {
      setStep(CHECKOUT_STEP_CHECKOUT)
    }
  }, [address, report])

  return (
    <CheckoutContext.Provider
      value={{
        address,
        prepareCheckout,
        finalizeCheckout,
        report,
        step,
        setAddress,
        setReport,
        setStep,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export const useCheckout = () => useContext(CheckoutContext)
